import Vue from 'vue';
import { mapKeys, snakeCase } from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import StarRating from "vue-star-rating";

export default {
    name: "card-comments",
    components: {
        StarRating,
    },

    data() {
        return {
            loader: true,
            dataProducts: {
                // id: {}
            }
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: "auth/isAuthenticated",
            reviewProductsData: "popups/currentModalPayload",
        }),
        ...mapMutations({
            changeReviewPopup: "popups/CHANGE_REVIEW_MODAL",
        }),
    },
    mounted() {
    },
    created() {
        this.generateFormFields();
    },
    methods: {
        generateFormFields() {
            this.reviewProductsData.products.data.forEach((item) => {
                this.dataProducts[item.id] = {
                    ...item,
                    loadBtn: false,
                    email: this.reviewProductsData.email,
                    name: this.reviewProductsData.name,
                    productId: item.id,
                    rating: 5,
                    message: '',
                };
            });
            this.loader = false;
        },
        ...mapActions({
            sendFeedback: "card/SEND_FEEDBACK",
        }),
        parsFormData(obj) {
            let fd = new FormData();
            for(let i in obj) {
                if(i === "files") {
                    for(let j in obj[i]) {
                        fd.append(`files[${j}]`, obj[i][j]);
                    }
                } else {
                    fd.append(i, obj[i]);
                }
            }
            return fd;
        },
        transformData(i) {
            return mapKeys(this.dataProducts[i], (value, key) => snakeCase(key));
        },

        submit(id) {
            this.dataProducts[id].loadBtn = true;
            this.sendFeedback(this.parsFormData(this.transformData(id)))
                .then(() => {
                    this.$toasted.success(this.$t("successFeedbackSend"));
                    Vue.delete(this.dataProducts, id);
                    console.log(Object.keys(this.dataProducts));
                    if(!Object.keys(this.dataProducts).length) {
                        this.changeReviewPopup(false);
                    }
                })
                .catch(() => {
                    this.$toasted.error(this.$t("validationRequired"));
                }).finally(() => {
                    this.dataProducts[id].loadBtn = false;
                });
        },
    },
};
