// import autocomplete from '@/components/autocomplete/index.vue'
import { mapActions, mapGetters, mapMutations } from "vuex";
// import buyFlowMixin from '@/mixins/buy-flow'

export default {
    name: "delivery-company-courier",
    // mixins: [buyFlowMixin],
    data() {
        return {
            town: "",
            townRef: "",
            department: "",
            zipCode: "",
            isValid: false,
            isValidTown: false,
            isValidDepartment: false,
            cityList: [],
            filterCityList: [],
            building: "",
            flat: "",
            selectedCity: null,
            selectedCityRef: null,
            selectedBranch: null,
        };
    },
    components: {
        // autocomplete
    },
    computed: {
        ...mapGetters({
            towns: "order/towns",
            departments: "order/departments",
            isTownsLoading: "order/isTownsLoading",
            isAddressLoading: "order/isAddressLoading",
            cities: "zasilkovna/cities",
            branches: "zasilkovna/branches",
        }),
    },
    watch: {
        selectedCity() {
            this.changeAddress(this.selectedCity);
            this.saveAddress({
                city: this.selectedCity,
                department: this.department,
                house: this.building,
                flat: this.flat,
                zip: this.zipCode
            })
            this.changeAddress({
                town: this.selectedCity,
                department: this.department,
                building: this.building,
                flat: this.flat,
                zip: this.zipCode
            });
        },
        department() {
            // this.checkValidData();
            // this.validateDelivery();
            this.saveAddress({
                city: this.selectedCity,
                department: this.department,
                house: this.building,
                flat: this.flat,
                zip: this.zipCode
            });
            this.changeAddress({
                town: this.selectedCity,
                department: this.department,
                building: this.building,
                flat: this.flat,
                zip: this.zipCode
            });
        },
        building() {
            // this.checkValidData();
            // this.validateDelivery();
            this.saveAddress({
                city: this.selectedCity,
                department: this.department,
                house: this.building,
                flat: this.flat,
                zip: this.zipCode
            });
            this.changeAddress({
                town: this.selectedCity,
                department: this.department,
                building: this.building,
                flat: this.flat,
                zip: this.zipCode
            });
        },
        zipCode() {
            this.saveAddress({
                city: this.selectedCity,
                department: this.department,
                house: this.building,
                flat: this.flat,
                zip: this.zipCode
            });
            this.changeAddress({
                town: this.selectedCity,
                department: this.department,
                building: this.building,
                flat: this.flat,
                zip: this.zipCode
            });
        },
        flat() {
            // this.checkValidData();
            // this.validateDelivery();
            this.saveAddress({
                city: this.selectedCity,
                department: this.department,
                house: this.building,
                flat: this.flat,
                zip: this.zipCode
            });
            this.changeAddress({
                town: this.selectedCity,
                department: this.department,
                building: this.building,
                flat: this.flat,
                zip: this.zipCode
            });
        },
    },
    created() {
    },
    methods: {
        ...mapActions({
            fetchTowns: "order/NOVA_POSHTA_GET_TOWNS",
            fetchDepartments: "order/NOVA_POSHTA_GET_DEPARTMENS",
            fetchCity: "zasilkovna/GET_CITY_LIST",
            fetchBranch: "zasilkovna/GET_BRANCH_LIST",
        }),
        ...mapMutations({
            setDepartments: "order/NOVA_POSHTA_SET_DEPARTMENS",
            changeAddress: "order/CHANGE_ADDRESS_COURIER",
            saveAddress: "order/SAVE_DELIVERY_ADDRESS",
        }),
        handleCityInput(e) {
            if (e.length < 3) {
                this.filterCityList = this.cityList.slice();
                return;
            }
            this.filterCityList = this.cityList.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));
        },
        handleCitySelect(e) {
            this.selectedCityRef = e.id;
            this.selectedCity = e.name;
            this.fetchBranch({city: this.selectedCity});
            this.$emit('select-city', e);
            this.selectedBranch = '';
        },
    },
};
