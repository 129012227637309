export default {
    install(Vue) {
        Vue.prototype.$apiLink = function(link) {
            const apiUrl = process.env.VUE_APP_API;
            return apiUrl + link;
        };
        Vue.prototype.$currency = function(currency) {
            if (currency === 'CZK') return 'Kč'
            return '€'
        };
    },
};
