// import autocomplete from '@/components/autocomplete/index.vue'
import { mapActions, mapGetters, mapMutations } from "vuex";
import { debounce } from "lodash";

export default {
    name: "delivery-carrier",
    data() {
        return {
            town: "",
            townRef: "",
            department: "",
            city: "",
            zipCode: "",
            isValid: false,
            isValidTown: false,
            isValidDepartment: false,
            countryList: [],
            filterCountryList: [],

            carrierList: [],
            filterCarrierList: [],
            building: "",
            flat: "",

            selectedCountry: null,
            selectedCountryRef: null,
            selectedCountryCode: null,

            selectedCarrier: null,
            selectedCarrierRef: null,

            selectedPickupPoint: null,
            selectedPickupPointRef: null,
            selectedBranch: null,

            carrierPickupPointList: [],
            filterCarrierPickupPointList: [],
            selectedPickupPointCarrier: null,
            selectedPickupPointCarrierRef: null,

            pickupPointList: [],
            filterPickupPointList: [],
            selectedPickupPoint: null,
            selectedPickupPointRef: null,

        };
    },
    components: {
        // autocomplete
    },
    computed: {
        ...mapGetters({
            towns: "order/towns",
            departments: "order/departments",
            isTownsLoading: "order/isTownsLoading",
            isAddressLoading: "order/isAddressLoading",
            cities: "zasilkovna/cities",
            branches: "zasilkovna/branches",
        }),
    },
    watch: {
        selectedCountry() {
            this.changeAddress(this.selectedCountry);
            this.selectedCarrier = null;
            this.selectedCarrierRef = null;
            this.selectedPickupPoint = null;
            this.selectedPickupPointRef = null;
            this.saveAddress({
                carrier: this.selectedCarrier,
                carrierRef: this.selectedCarrierRef,
                country: this.selectedCountry,
                countryCode: this.selectedCountryCode,
                pickupPoint: this.selectedPickupPoint,
                pickupPointRef: this.selectedPickupPointRef,
            })
            this.changeAddress({
                carrier: this.selectedCarrier,
                carrierRef: this.selectedCarrierRef,
                country: this.selectedCountry,
                countryCode: this.selectedCountryCode,
                pickupPoint: this.selectedPickupPoint,
                pickupPointRef: this.selectedPickupPointRef,
            });
        },
        selectedCarrier() {
            this.changeAddress(this.selectedCountry);
            this.selectedPickupPoint = null;
            this.selectedPickupPointRef = null;
            this.saveAddress({
                carrier: this.selectedCarrier,
                carrierRef: this.selectedCarrierRef,
                country: this.selectedCountry,
                countryCode: this.selectedCountryCode,
                pickupPoint: this.selectedPickupPoint,
                pickupPointRef: this.selectedPickupPointRef,
            })
            this.changeAddress({
                carrier: this.selectedCarrier,
                carrierRef: this.selectedCarrierRef,
                country: this.selectedCountry,
                countryCode: this.selectedCountryCode,
                pickupPoint: this.selectedPickupPoint,
                pickupPointRef: this.selectedPickupPointRef,
            });
        },
        selectedPickupPoint() {
            this.changeAddress(this.selectedCountry);
            this.saveAddress({
                carrier: this.selectedCarrier,
                carrierRef: this.selectedCarrierRef,
                country: this.selectedCountry,
                countryCode: this.selectedCountryCode,
                pickupPoint: this.selectedPickupPoint,
                pickupPointRef: this.selectedPickupPointRef,
            })
            this.changeAddress({
                carrier: this.selectedCarrier,
                carrierRef: this.selectedCarrierRef,
                country: this.selectedCountry,
                countryCode: this.selectedCountryCode,
                pickupPoint: this.selectedPickupPoint,
                pickupPointRef: this.selectedPickupPointRef,
            });
        },
    },
    created() {
        this.fetchCountry()
            .then(res => {
                this.countryList = res;
                this.filterCountryList = res;
            })
            .catch(error => {
                console.log('fetchCountry ERROR => ', error);
            });
    },
    methods: {
        ...mapActions({
            fetchTowns: "order/NOVA_POSHTA_GET_TOWNS",
            fetchDepartments: "order/NOVA_POSHTA_GET_DEPARTMENS",
            fetchCity: "zasilkovna/GET_CITY_LIST",
            fetchCountry: "zasilkovna/GET_COUNTRIES_LIST",
            fetchCarriersList: "zasilkovna/GET_CARRIER_PICKUP_POINTS_LIST",
            fetchBranch: "zasilkovna/GET_BRANCH_LIST",
            fetchCarrierPickupPointList: "zasilkovna/GET_CARRIER_PICKUP_POINTS_LIST",
            fetchPickupPointList: "zasilkovna/GET_PICKUP_POINTS_LIST",
        }),
        ...mapMutations({
            setDepartments: "order/NOVA_POSHTA_SET_DEPARTMENS",
            changeAddress: "order/CHANGE_ADDRESS_COURIER",
            saveAddress: "order/SAVE_DELIVERY_ADDRESS",
        }),

        getHandlerCarrier(country) {
            const payload = {
                country: country.alpha2Code,
                search: '',
                with_cod: false,
            }
            this.fetchCarriersList(payload)
                .then(res => {
                    this.carrierList = res;
                    this.filterCarrierList = res;
                    // console.log('fetchCarriersList ====> ', res);
                })
                .catch(error => {
                    console.log('fetchCarriersList ERROR ====> ', error);
                })
        },
        handleCountryInput(e) {
            if(e.length < 3) {
                this.filterCountryList = this.countryList.slice();
                return;
            }
            this.filterCountryList = this.countryList.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));
        },
        handleCarrierInput(e) {
            if(e.length < 3) {
                this.filterCarrierList = this.carrierList.slice();
                return;
            }
            this.filterCarrierList = this.carrierList.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));
        },
        handlePickupPointInput: debounce(async function(e) {
            if(e.length < 3) {
                try {
                    const response = await this.fetchPickupPointList({carrierId: this.selectedCarrierRef, search: e})
                    this.filterPickupPointList = response
                    this.pickupPointList = response
                } catch (e) {
                    console.error('handlePickupPointInput Error: ', e)
                }
                return;
            }

            try {
                const response = await this.fetchPickupPointList({carrierId: this.selectedCarrierRef, search: e})
                this.filterPickupPointList = response
            } catch (e) {
                console.error('handlePickupPointInput Error: ', e)
            }
        }, 300),

        handleCountrySelect(e) {
            console.log(e);
            this.selectedCountryRef = e.id;
            this.selectedCountryCode = e.alpha2Code;
            this.selectedCountry = e.name;
            this.$emit('select-country', {
                country: e.alpha2Code,
            });
            this.selectedBranch = '';
            this.getHandlerCarrier(e);
            this.selectedCarrierRef = null;
            this.selectedCarrier = null;
            this.selectedPickupPointCarrier = null;
            this.selectedPickupPointCarrierRef = null;
        },
        async handleCarrierSelect(e) {
            this.selectedCarrierRef = e.id;
            this.selectedCarrier = e.name;
            this.$emit('select-carrier', {
                country: this.selectedCountryCode,
            });
            this.selectedBranch = '';
            try {
                this.pickupPointList = await this.fetchPickupPointList({carrierId: e.id})
                this.filterPickupPointList = this.pickupPointList;
            } catch (e) {
                console.error('handleCarrierSelect Error: ', e)
                this.$toasted.error(this.$t("somethingWentWrongTryAgainLater"));
            }
        },
        handlePickupPointSelect(e) {
            this.selectedPickupPointRef = e.code;
            this.selectedPickupPoint = e.name;
            this.$emit('select-carrier-pickup-points', e);
            this.selectedBranch = '';
        },
    },
};
