import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import { $http } from "@/utils/https";
import type { Product } from "@/types/shop";

interface State {
    cities: any;
    branches: any;
    countries: any,
    carriers: any,
    carriersPickupPoints: any,
    pickupPoints: any,
}

const state: State = {
    cities: null,
    branches: null,
    countries: null,
    carriers: null,
    carriersPickupPoints: null,
    pickupPoints: null,
}

const getters = {
    cities: (state: State) => state.cities,
    branches: (state: State) => state.branches,
    countries: (state: State) => state.countries,
    carriers: (state: State) => state.carriers,
    carriersPickupPoints: (state: State) => state.carriersPickupPoints,
    pickupPoints: (state: State) => state.pickupPoints,
}

const actions = {
    GET_CARRIERS_LIST: async (
        { commit }: ActionContext<State, RootState>,
        payload: {
            country: string,
            search: string,
            with_cod: boolean,
        }
    ) => {
        commit('SET_CARRIERS_LIST', []);
        const url = `v1/delivery/carriers_couriers?country=${payload.country}&with_cod=${payload.with_cod}`;
        try {
            const response = await $http.get<{ data: Product }>(url);
            console.log('GET_CARRIERS_LIST => ', response);
            commit('SET_CARRIERS_LIST', response.data.data);
            return response.data.data;
        } catch(e) {
            throw e;
        } finally {}
    },
    GET_CITY_LIST: async ({commit}: ActionContext<State, RootState>) => {
        commit('SET_CITIES_LIST', []);
        try {
            const response = await $http.get<{ data: Product }>(`v1/delivery/cities`);
            commit('SET_CITIES_LIST', response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {}
    },
    GET_COUNTRIES_LIST: async ({commit}: ActionContext<State, RootState>) => {
        commit('SET_COUNTRIES_LIST', []);

        try {
            const response = await $http.get<{ data: Product }>('/v1/delivery/countries');
            commit('SET_COUNTRIES_LIST', response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {}
    },
    GET_BRANCH_LIST: async ({commit}: ActionContext<State, RootState>, payload: {city: string, search?: string}) => {
        commit('SET_BRANCHES_LIST', null);
        let url = `v1/delivery/branches?city=${payload.city}`;
        if (!!payload.search) {
            url += `&search=${payload.search}`;
        }
        try {
            const response = await $http.get<{ data: Product }>(url);
            commit('SET_BRANCHES_LIST', response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {}
    },
    GET_CARRIER_PICKUP_POINTS_LIST: async (
        { commit }: ActionContext<State, RootState>,
        payload: {
            country: string,
            search: string,
            with_cod: boolean,
        }
    ) => {
        const {
            country,
            search,
            with_cod,
        } = payload;
        commit('SET_CARRIER_PICKUP_POINTS_LIST', []);

        try {
            const response = await $http.get<{ data: Product }>
                (`/v1/delivery/carriers_pickup_points?country=${country}&search=${search}&with_cod=${with_cod}`);
            commit('SET_CARRIER_PICKUP_POINTS_LIST', response.data.data);
            return response.data.data;
        } catch(e) {
            throw e;
        } finally {}
    },
    GET_PICKUP_POINTS_LIST: async (
        { commit }: ActionContext<State, RootState>,
        payload: {
            carrierId: string,
            search: string | null,
        }
    ) => {
        const {
            carrierId,
            search
        } = payload;
        commit('SET_PICKUP_POINTS_LIST', []);

        try {
            let url = `/v1/delivery/pickup_points_by_carrier?carrier=${carrierId}`
            if (search) url += `&search=${search}`;
            const response = await $http.get<{ data: Product }>(url);
            commit('SET_PICKUP_POINTS_LIST', response.data.data);
            return response.data.data;
        } catch(e) {
            throw e;
        }
    },
}

const mutations = {
    SET_CITIES_LIST(state: State, value: any) {
        state.cities = value;
    },
    SET_BRANCHES_LIST(state: State, value: any) {
        state.branches = value;
    },
    SET_COUNTRIES_LIST(state: State, value: any) {
        state.countries = value;
    },
    SET_CARRIERS_LIST(state: State, value: any) {
        state.carriers = value;
    },
    SET_CARRIER_PICKUP_POINTS_LIST(state: State, value: any) {
        state.carriersPickupPoints = value;
    },
    SET_PICKUP_POINTS_LIST(state: State, value: any) {
        state.pickupPoints = value;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
