import { mapActions, mapGetters } from "vuex";
import defaultPrice from "../../../modules/buy-flow/pages/basket/components/default-price/index.vue";
import { sumBy, map } from "lodash";
import { downloadFileFrom } from "@/helpers";

export default {
    name: "default-prices",
    components: {
        defaultPrice,
    },
    data() {
        return {};
    },
    created() {
        document.getElementById("html").classList.add("hide");
    },
    computed: {
        ...mapGetters({
            productsPdf: "order/productsPdf",
            basket: "basket/basket",
            downloadPdfLoading: "order/downloadPdfLoading",
            user: "profile/user",
            isDistributor: "profile/isDistributor",
            isProfessional: "profile/isProfessional",
        }),
        totalSumClient() {
            let sum = 0

            this.basket.map(product => {
                if (product.select_type.clientPrice) {
                    sum += (product.client_with_code_price?.price || 0) * product.select_count
                }
            })

            return sum
        },
    },
    methods: {
        ...mapActions({
            fetchPdf: `order/DOWNLOAD_PDF`,
        }),
        async downloadPdf() {
            const products = map(this.basket, product => ({
                id: product.id,
                value: product.select_type.value,
                count: product.select_count,
            }));

            await this.fetchPdf({ products });
            await downloadFileFrom(this.productsPdf.path, "prices.pdf");
        },
    },
};
